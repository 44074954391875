export const message_block = [
    {
        "type": "MESSAGE_PLAYER_SEND_MESSAGE",
        "tooltip": "给一个玩家发送消息",
        "helpUrl": "",
        "message0": "给 %1 发送一条 %2 消息 %3",
        "args0": [
            {
                "type": "input_value",
                "name": "1",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "2",
                "check": "string"
            },
            {
                "type": "input_dummy",
                "name": "3"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 105,
        "inputsInline": true
    },
    {
        "type": "MESSAGE_PLAYER_SEND_MESSAGE_ALL",
        "tooltip": "",
        "helpUrl": "",
        "message0": "给 全体玩家 %1 发送一条 %2 消息 %3",
        "args0": [
            {
                "type": "input_dummy",
                "name": "1"
            },
            {
                "type": "input_value",
                "name": "2",
                "check": "string"
            },
            {
                "type": "input_dummy",
                "name": "3"
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "colour": 105,
        "inputsInline": true
    },
    {
        "type": "MESSAGE_SET_JOIN_MESSAGE",
        "tooltip": "设置加入服务器消息",
        "helpUrl": "",
        "message0": "设置加入服务器消息 %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 90
    },
    {
        "type": "MESSAGE_SET_QUIT_MESSAGE",
        "tooltip": "设置退出服务器消息",
        "helpUrl": "",
        "message0": "设置退出服务器消息 %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 90
    },
    {
        "type": "MESSAGE_SET_CHAT_FORMAT",
        "tooltip": "仅玩家聊天事件可用",
        "helpUrl": "",
        "message0": "设置聊天格式 %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 90
    },
    {
        "type": "MESSAGE_GET_CHAT_MESSAGE",
        "tooltip": "获取聊天内容",
        "helpUrl": "",
        "message0": "获取聊天内容 %1",
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 135
    },
    {
        "type": "MESSAGE_GET_COMMAND",
        "tooltip": "获取指令内容",
        "helpUrl": "",
        "message0": "获取指令内容 %1",
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 135
    }, {
        "type": "MESSAGE_GET_PAPI_RESULT",
        "tooltip": "papi占位符，例如 %server_tps%，如果是和玩家变量相关的占位符，需要使用下面的方法",
        "helpUrl": "",
        "message0": "获得papi变量结果 papi占位符： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 135
    }, {
        "type": "MESSAGE_GET_PAPI_RESULT_PLAYER",
        "tooltip": "papi占位符，例如 %player_name%",
        "helpUrl": "",
        "message0": "获得papi变量结果 papi占位符： %1 玩家： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "PLAYER",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 135
    }



]