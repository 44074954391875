import * as hans from 'blockly/msg/zh-hans'
import * as Blockly from 'blockly/core';
import {javascriptGenerator} from 'blockly/javascript';
import {index_block} from "@/data/customize/index_block";
import {index_category} from "@/data/customize/index_category";
import "/src/data/customize/index_generator"
import {DisableTopBlocks} from "@blockly/disable-top-blocks";
import {FixedEdgesMetricsManager} from "@blockly/fixed-edges";
import {
    createTriggerApi,
    deleteTriggerApi,
    getTriggerComponentListApi,
    getUserTriggerListApi,
    saveAndUpdateTriggerApi
} from "@/api/file";
import gzipUtil from "@/utils/gzipUtil";
import {createPlusField} from "@/utils/field_plus";
import {createMinusField} from "@/utils/field_minus";
import '@/utils/if';

export const mixin = {
    data() {
        return {
            createEvent: false,
            checkChange: false,
            workspace: null,
            generatedCode: '',
            saveCodeJson: {},
            originalToolbox: [...index_category],
            baseEvent: "",
            fileUuid: "",
            fileName: "",
            input: "",
            isShow: true,
            searchTriggerName: "",
            userTriggerList: [],
            triggerComponentList: [],
            trigger: {
                fileUuid: "",
                type: "",
                name: "",
                description: ""
            },
            triggerSelIndex: "",
            triggerItem: {
                triggerUuid: "",
                description: "",
                enable: "",
                schema: "",
                expression: "",
            },
            userInfo: ""
        };
    },
    mounted() {
        let {
            fileUuid,
            fileName
        } = this.$route.query
        if (!fileUuid && !fileName) {
            this.$router.push("/workspace")
        }
        this.fileUuid = fileUuid
        this.fileName = fileName
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
        this.getUserTriggerList()
    },
    methods: {
        goHome(){
            this.$router.push("/")
        },
        goBack() {
            this.$router.back()
        },
        createElement() {
            console.log(this.$refs.blocklyDiv)
        },
        searchToolbox(event) {
            const searchTerm = event.toLowerCase();

            // 过滤工具箱内容
            const filteredToolbox = this.originalToolbox.map(category => {
                const filteredContents = category.contents.filter(block => block.name.toLowerCase().includes(searchTerm)
                );
                return {...category, contents: filteredContents};
            }).filter(category => category.contents.length > 0);
            // 更新工具箱
            this.workspace.updateToolbox({
                kind: "categoryToolbox",
                contents: filteredToolbox
            });
        },
        initializeBlockly() {
            //使用中文包
            Blockly.setLocale(hans);
            //定义组件
            Blockly.defineBlocksWithJsonArray(index_block);
            FixedEdgesMetricsManager.setFixedEdges({
                top: true,
                left: true,
            });


            /*Blockly.Trashcan.prototype.createDom = function() {
                this.svgGroup_ = Blockly.utils.dom.createSvgElement('g', {'class': 'blocklyTrash'}, null);

                // 创建垃圾桶的自定义图标
                this.svgImg_ = Blockly.utils.dom.createSvgElement('image', {
                    'width': 148,  // 自定义图标的宽度
                    'height': 148, // 自定义图标的高度
                    'href': 'https://webapi.domcer.com/img/DoMCer-logotxt.png'  // 本地图片路径
                }, this.svgGroup_);
                const xPosition = 20;
                const yPosition = 800;
                this.svgGroup_.setAttribute('transform', `translate(${xPosition}, ${yPosition})`);
                return this.svgGroup_;
            };
*/

            this.workspace = Blockly.inject('blocklyDiv', {
                toolboxPosition: 'end',
                toolbox: {
                    kind: "categoryToolbox",
                    contents: [...index_category]
                },
                grid:
                    {
                        spacing: 20,
                        length: 3,
                        colour: '#ccc',
                        snap: true
                    },
                trashcan: true,
                plugins: {
                    metricsManager: FixedEdgesMetricsManager,
                },
            });



            this.loadCode()
            this.onChange()


        },
        createBlock(type) {
            // 创建一个新的块实例
            const block = this.workspace.newBlock(type);
            block.initSvg();
            block.render();

            // 设置块的位置到鼠标处（例如在工作区的左上角），根据需要调整
            block.moveBy(100, 50);
        },
        generateCode() {
            let command = javascriptGenerator.workspaceToCode(this.workspace);
            this.triggerItem.expression = gzipUtil.compressNoJsonToBase64(command);
        },
        saveCode() {
            this.saveCodeJson = JSON.parse(JSON.stringify(Blockly.serialization.workspaces.save(Blockly.getMainWorkspace())))
            this.triggerItem.schema = gzipUtil.compressToBase64(this.saveCodeJson)
        },
        loadCode() {
            Blockly.serialization.workspaces.load(this.saveCodeJson, this.workspace)
        },
        onChange() {
            // 添加未连接积木禁用插件
            this.workspace.addChangeListener(Blockly.Events.disableOrphans);
            const disableTopBlocksPlugin = new DisableTopBlocks();
            disableTopBlocksPlugin.init();

            // 为积木注册 onchange 事件
            Blockly.getMainWorkspace().addChangeListener((event) => {
                let eventWorkspace = event.getEventWorkspace_();
                let allBlocks = eventWorkspace.getAllBlocks();
                if (event.type === "drag") {
                    this.checkChange = true;
                }

                // 校验根块只能有一个
                const triggerBlocks = allBlocks.filter(block => block.type === 'TRIGGER_BLOCK');
                if (triggerBlocks.length > 1) {
                    // 删除多余的块
                    for (let i = 1; i < triggerBlocks.length; i++) {
                        triggerBlocks[i].dispose(false, true);
                    }
                }

                allBlocks.forEach(block => {
                    if ((block.type === "MESSAGE_SET_CHAT_FORMAT" || block.type === "MESSAGE_GET_CHAT_MESSAGE") &&
                        (this.baseEvent !== "AsyncPlayerChatEvent" && this.baseEvent !== "PlayerChatEvent")) {
                        block.setWarningText("错误的触发器，该方法只能用于 玩家聊天 触发器")
                    }
                    if ((block.type === "MESSAGE_GET_COMMAND") &&
                        this.baseEvent !== "PlayerCommandPreprocessEvent") {
                        block.setWarningText("错误的触发器，该方法只能用于 玩家指令 触发器")
                    }
                    if (block.type === "TRIGGER_BLOCK") {
                        block.setDeletable(false)
                    }
                    if (block.type === "SERVER_SET_MOTD" && this.baseEvent !== "PlayerCommandPreprocessEvent") {
                        block.setWarningText("错误的触发器，该方法只能用于 服务器列表 触发器")
                    }
                    if ((block.type === "LOCATION_GET_FROM" || block.type === "LOCATION_GET_TO") &&
                        this.baseEvent !== "PlayerMoveEvent") {
                        block.setWarningText("错误的触发器，该方法只能用于 玩家移动 触发器")
                    }
                    if ((block.type === "EVENT_GET_LOCALE") &&
                        this.baseEvent !== "PlayerLocaleChangeEvent") {
                        block.setWarningText("错误的触发器，该方法只能用于 玩家改变语言设置 触发器")
                    }
                    if ((block.type === "PLAYER_GET_KILLER") &&
                        this.baseEvent !== "PlayerDeathEvent") {
                        block.setWarningText("错误的触发器，该方法只能用于 玩家死亡 触发器")
                    }


                })
                this.generateCode();
            });
            const listCreateMutator = {
                itemCount_: 0,

                mutationToDom: function () {
                    const container = Blockly.utils.xml.createElement('mutation');
                    container.setAttribute('items', this.itemCount_);
                    return container;
                },
                domToMutation: function (xmlElement) {
                    const targetCount = parseInt(xmlElement.getAttribute('items'), 10);
                    this.updateShape_(targetCount);
                },

                saveExtraState: function () {
                    return {
                        itemCount: this.itemCount_,
                    };
                },
                loadExtraState: function (state) {
                    this.updateShape_(state['itemCount']);
                },

                updateShape_: function (targetCount) {
                    while (this.itemCount_ < targetCount) {
                        this.addPart_();
                    }
                    while (this.itemCount_ > targetCount) {
                        this.removePart_();
                    }
                    this.updateMinus_();
                },

                plus: function () {
                    this.addPart_();
                    this.updateMinus_();
                },

                minus: function () {
                    if (this.itemCount_ == 0) {
                        return;
                    }
                    this.removePart_();
                    this.updateMinus_();
                },
                addPart_: function () {
                    if (this.itemCount_ == 0) {
                        this.removeInput('EMPTY');
                        this.topInput_ = this.appendValueInput('ADD' + this.itemCount_)
                            .appendField(createPlusField(), 'PLUS')
                            .appendField(Blockly.Msg['LISTS_CREATE_WITH_INPUT_WITH']);
                    } else {
                        this.appendValueInput('ADD' + this.itemCount_);
                    }
                    this.itemCount_++;
                },
                removePart_: function () {
                    this.itemCount_--;
                    this.removeInput('ADD' + this.itemCount_);
                    if (this.itemCount_ == 0) {
                        this.topInput_ = this.appendDummyInput('EMPTY')
                            .appendField(createPlusField(), 'PLUS')
                            .appendField(Blockly.Msg['LISTS_CREATE_EMPTY_TITLE']);
                    }
                },
                updateMinus_: function () {
                    const minusField = this.getField('MINUS');
                    if (!minusField && this.itemCount_ > 0) {
                        this.topInput_.insertFieldAt(1, createMinusField(), 'MINUS');
                    } else if (minusField && this.itemCount_ < 1) {
                        this.topInput_.removeField('MINUS');
                    }
                },
            };

            const listCreateHelper = function () {
                this.getInput('EMPTY').insertFieldAt(0, createPlusField(), 'PLUS');
                this.updateShape_(3);
            };

           /* Blockly.Extensions.registerMutator(
                'new_list_create_with_mutator',
                listCreateMutator,
                listCreateHelper,
            );*/
        },
        async getUserTriggerList() {
            let fileUuid = this.fileUuid
            let {data} = await getUserTriggerListApi({
                fileUuid
            })
            this.userTriggerList = data
        },
        openCreateTrigger() {
            if (this.triggerSelIndex !== "" && this.checkChange) {
                this.triggerSelIndex = ""
                this.$confirm(`您还未保存当前触发器, 是否继续?`, '提示', {
                    confirmButtonText: '保存',
                    cancelButtonText: '不保存',
                    type: 'warning'
                }).then(() => {
                    this.saveToServe()
                    this.resetWorkspace()
                    this.trigger = {
                        fileUuid: "",
                        type: "",
                        name: "",
                        description: ""
                    }
                    this.trigger.fileUuid = this.fileUuid
                    this.getTriggerComponentList()
                    this.createEvent = true
                }).catch(() => {
                    this.resetWorkspace()
                    this.trigger = {
                        fileUuid: "",
                        type: "",
                        name: "",
                        description: ""
                    }
                    this.trigger.fileUuid = this.fileUuid
                    this.getTriggerComponentList()
                    this.createEvent = true
                });
            } else {
                this.triggerSelIndex = ""
                this.resetWorkspace()
                this.trigger = {
                    fileUuid: "",
                    type: "",
                    name: "",
                    description: ""
                }
                this.trigger.fileUuid = this.fileUuid
                this.getTriggerComponentList()
                this.createEvent = true
            }

        },
        async createTrigger() {
            if (!this.trigger.type) {
                this.$message.error("请选择触发器类型")
                return
            }
            if (!this.trigger.name) {
                this.$message.error("请输入触发器名称")
                return
            }
            await createTriggerApi(this.trigger)
            this.$message.success("创建成功")
            this.getUserTriggerList()
            this.createEvent = false
            this.triggerSelIndex = ""
        },
        async getTriggerComponentList() {
            let {data} = await getTriggerComponentListApi({
                type: "EVENT"
            })
            this.triggerComponentList = data
        },
        resetWorkspace() {
            if (this.workspace) {
                Blockly.getMainWorkspace().dispose();
                this.workspace = null;
            }
        },
        choiceTrigger(index, item, e) {
            if (e.target._prevClass === "el-icon-delete-solid") {
                this.deleteTrigger(item.uuid, item.name)
                return;
            }
            if (this.triggerSelIndex !== "" && this.checkChange) {
                this.$confirm(`您还未保存当前触发器, 是否继续?`, '提示', {
                    confirmButtonText: '保存',
                    cancelButtonText: '不保存',
                    type: 'warning'
                }).then(() => {
                    this.saveToServe()
                    this.switchTrigger(index, item)
                }).catch(() => {
                    this.switchTrigger(index, item)
                });
            } else {
                this.switchTrigger(index, item)
            }

        },
        switchTrigger(index, item) {
            this.resetWorkspace()
            this.resetItem()
            this.triggerSelIndex = index
            this.triggerItem.triggerUuid = item.uuid
            this.triggerItem.description = item.description
            this.triggerItem.enable = item.enable
            this.triggerItem.schema = item.schema
            this.baseEvent = item.type
            this.checkChange = false
            this.saveCodeJson = gzipUtil.decompressFromBase64(item.schema)
            this.initializeBlockly()
        },
        async saveToServe() {
            this.saveCode()
            this.generateCode()
            let triggerItem = JSON.parse(JSON.stringify(this.triggerItem))
            await saveAndUpdateTriggerApi(triggerItem)
            this.getUserTriggerList()
            this.$message.success("保存成功")
        },
        deleteTrigger(uuid, name) {
            this.$confirm(`此操作将永久删除 ${name} 触发器, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await deleteTriggerApi({triggerUuid: uuid})
                this.triggerSelIndex = ""
                this.getUserTriggerList()
                this.resetWorkspace()
                this.resetItem()
                this.$message.success("删除成功")
            }).catch(() => {
            });


        },
        resetItem() {
            this.triggerItem = {
                triggerUuid: "",
                description: "",
                enable: "",
                schema: "",
                expression: "",
            }
            this.baseEvent = ""
        },

    }
}
