export const base_block = [
    {
        "type": "BASE_SYMBOL",
        "tooltip": "数量两个值输出布尔值",
        "helpUrl": "",
        "message0": "值: %1 %2 %3 值: %4",
        "args0": [
            {
                "type": "input_value",
                "name": "1"
            },
            {
                "type": "field_dropdown",
                "name": "4",
                "options": [
                    [
                        "=",
                        "=="
                    ],
                    [
                        "<",
                        "<"
                    ],
                    [
                        ">",
                        ">"
                    ],
                    [
                        "<=",
                        "<="
                    ],
                    [
                        ">=",
                        ">="
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "2"
            },
            {
                "type": "input_value",
                "name": "3"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    },
    {
        "type": "BASE_NUMBER",
        "tooltip": "创建一个数字",
        "helpUrl": "",
        "message0": "数字： %1 %2",
        "args0": [
            {
                "type": "field_number",
                "name": "NUMBER",
                "value": 0
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "number",
        "colour": 15
    },
    {
        "type": "BASE_STRING",
        "tooltip": "创建一个文本",
        "helpUrl": "",
        "message0": "文本： %1",
        "args0": [
            {
                "type": "field_input",
                "name": "STRING_NAME",
                "text": ""
            }
        ],
        "output": "string",
        "colour": 15
    }
    ,
    {
        "type": "BASE_TO_STRING",
        "tooltip": "将一个数字转换为字符串",
        "helpUrl": "",
        "message0": "转换字符串 %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "string",
        "colour": 15
    },
    {
        "type": "BASE_BOOLEAN",
        "tooltip": "创建一个布尔类型",
        "helpUrl": "",
        "message0": "布尔： %1 %2",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "BOOLEAN",
                "options": [
                    [
                        "是",
                        "true"
                    ],
                    [
                        "否",
                        "false"
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_CONCAT_STRING",
        "tooltip": "字符串拼接",
        "helpUrl": "",
        "message0": "字符串拼接 字符串1： %1 字符串2： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 15
    },
    {
        "type": "BASE_NOT",
        "tooltip": "将一个布尔值取反",
        "helpUrl": "",
        "message0": "逻辑取反 布尔： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "boolean_block"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_GENERATE_UUID",
        "tooltip": "结果是一个字符串，不带 '-'",
        "helpUrl": "",
        "message0": "生成一串随机的UUID字符串 %1",
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 15
    }, {
        "type": "BASE_SUB_STRING_1",
        "tooltip": "对应后端 str.subString(num)，从第几位开始截取后面的所有内容",
        "helpUrl": "",
        "message0": "截取字符串 字符串： %1 第几位开始： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "string",
        "colour": 15,
        "inputsInline": false
    }, {
        "type": "BASE_SUB_STRING_2",
        "tooltip": "对应后端 str.subString(num, num)，从第几位到第几位开始截取内容",
        "helpUrl": "",
        "message0": "截取字符串 字符串： %1 第几位开始： %2 第几位结束： %3",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "number"
            }, {
                "type": "input_value",
                "name": "start2",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "string",
        "colour": 15,
        "inputsInline": false
    }, {
        "type": "BASE_IS_NULL",
        "tooltip": "可以传递任何一个内容，判断这个内容是否为空",
        "helpUrl": "",
        "message0": "判断值是否为空 值： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_OPERATE",
        "tooltip": "四则运算，可以对数值加减乘除",
        "helpUrl": "",
        "message0": "数字： %1 %2 %3 数字： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "NUM1",
                "check": "number"
            },
            {
                "type": "field_dropdown",
                "name": "NAME",
                "options": [
                    [
                        "加",
                        "+"
                    ],
                    [
                        "减",
                        "-"
                    ],
                    [
                        "乘",
                        "*"
                    ],
                    [
                        "除",
                        "/"
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            },
            {
                "type": "input_value",
                "name": "NUM2",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_ABS",
        "tooltip": "求数值绝对值",
        "helpUrl": "",
        "message0": "求数值绝对值 值： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_REMAINDER",
        "tooltip": "A除以B，余结果",
        "helpUrl": "",
        "message0": "求余 值A： %1 值B： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_RANDOM_NUMBER",
        "tooltip": "求一个范围的随机数，从0开始，如果填写的内容为2，那么只会随机到0和1，结果为整数",
        "helpUrl": "",
        "message0": "取一个范围内的随机数 范围： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_AND_OR",
        "tooltip": "与 或",
        "helpUrl": "",
        "message0": "布尔： %1 %2 %3 布尔： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "NUM1",
                "check": "boolean_block"
            },
            {
                "type": "field_dropdown",
                "name": "NAME",
                "options": [
                    [
                        "与",
                        "&&"
                    ],
                    [
                        "或",
                        "||"
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            },
            {
                "type": "input_value",
                "name": "NUM2",
                "check": "boolean_block"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }












]
