export const base_contents = [
    {
        "kind": "block",
        "type": "BASE_SYMBOL",
        "name": "基本块判断"
    },
    {
        "kind": "block",
        "type": "BASE_NUMBER",
        "name": "基本块数字"
    },
    {
        "kind": "block",
        "type": "BASE_STRING",
        "name": "基本块字符串"
    },
    {
        "kind": "block",
        "type": "BASE_BOOLEAN",
        "name": "基本块布尔"
    },
    {
        "kind": "block",
        "type": "BASE_TO_STRING",
        "name": "转换成字符串"
    }, {
        "kind": "block",
        "type": "BASE_CONCAT_STRING",
        "name": "字符串拼接"
    }, {
        "kind": "block",
        "type": "BASE_NOT",
        "name": "取反"
    }, {
        "kind": "block",
        "type": "BASE_AND_OR",
        "name": "与 或"
    }, {
        "kind": "block",
        "type": "BASE_GENERATE_UUID",
        "name": "生成一串随机的UUID字符串"
    }, {
        "kind": "block",
        "type": "BASE_SUB_STRING_1",
        "name": "截取字符串"
    }, {
        "kind": "block",
        "type": "BASE_SUB_STRING_2",
        "name": "截取字符串"
    }, {
        "kind": "block",
        "type": "BASE_IS_NULL",
        "name": "判断值是否为空"
    }, {
        "kind": "block",
        "type": "BASE_OPERATE",
        "name": "四则运算"
    }, {
        "kind": "block",
        "type": "BASE_ABS",
        "name": "求数值绝对值"
    }, {
        "kind": "block",
        "type": "BASE_REMAINDER",
        "name": "求余"
    }, {
        "kind": "block",
        "type": "BASE_RANDOM_NUMBER",
        "name": "取一个范围内的随机数"
    },
]
