export const scoreboard_block = [
    {
        "type": "SCOREBOARD_CREATE",
        "tooltip": "行 是个字符串json，例如 {\"123\",\"123\"}",
        "helpUrl": "",
        "message0": "创建一个计分板 标题： %1 行： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "lines",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "scoreboard",
        "colour": 270
    }, {
        "type": "SCOREBOARD_SET_SCOREBOARD",
        "tooltip": "",
        "helpUrl": "",
        "message0": "给玩家设定一个计分板 玩家： %1 计分板： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "SCOREBOARD",
                "align": "RIGHT",
                "check": "scoreboard"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 270
    }, {
        "type": "SCOREBOARD_RESET_SCOREBOARD",
        "tooltip": "",
        "helpUrl": "",
        "message0": "重置一个玩家的计分板 玩家： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 270
    }

]