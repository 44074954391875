import {javascriptGenerator, Order} from "blockly/javascript";

// 两个值判断
javascriptGenerator.forBlock['BASE_SYMBOL'] = function (block, generator) {
    const value_1 = generator.valueToCode(block, '1', Order.NONE);
    const dropdown_4 = block.getFieldValue('4');
    const value_3 = generator.valueToCode(block, '3', Order.NONE);

    const code = `${value_1}${dropdown_4}${value_3}`;
    return [code, Order.NONE];
}

// 数字
javascriptGenerator.forBlock['BASE_NUMBER'] = function (block) {
    const number_number = block.getFieldValue('NUMBER');

    const code = `${number_number}`;
    return [code, Order.NONE];
}

// 字符串
javascriptGenerator.forBlock['BASE_STRING'] = function (block, generator) {
    const text_string_name = block.getFieldValue('STRING_NAME');
    let code = `\"${text_string_name}\"`;


    return [code, Order.NONE];
}

// 布尔值
javascriptGenerator.forBlock['BASE_BOOLEAN'] = function (block, generator) {
    const dropdown_boolean = block.getFieldValue('BOOLEAN');

    const code = `${dropdown_boolean}`;
    return [code, Order.NONE];
}

// 转换数字成字符串
javascriptGenerator.forBlock['BASE_TO_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#toString(${value_name})`;
    return [code, Order.NONE];
}

// 拼接字符串
javascriptGenerator.forBlock['BASE_CONCAT_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    const code = `#concatString(${value_name},${value_name2})`;
    return [code, Order.NONE];
}

// 逻辑取反
javascriptGenerator.forBlock['BASE_NOT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#not(${value_name})`;
    return [code, Order.NONE];
}

// 生成UUID
javascriptGenerator.forBlock['BASE_GENERATE_UUID'] = function () {

    const code = `#generateUUID()`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_SUB_STRING_1'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);

    const code = `#subString(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_SUB_STRING_2'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);
    const value_start2 = generator.valueToCode(block, 'start2', Order.NONE);

    const code = `#subString(${value_name},${value_start},${value_start2})`;
    return [code, Order.NONE];
}


javascriptGenerator.forBlock['BASE_IS_NULL'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#isNull(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_OPERATE'] = function (block, generator) {
    const value_num1 = generator.valueToCode(block, 'NUM1', Order.NONE);
    const dropdown_name = block.getFieldValue('NAME');
    const value_num2 = generator.valueToCode(block, 'NUM2', Order.NONE);

    const code = `${value_num1}${dropdown_name}${value_num2}`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_ABS'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);

    const code = `#abs(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_REMAINDER'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#remainder(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_RANDOM_NUMBER'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#randomNumber(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_AND_OR'] = function (block, generator) {
    const value_num1 = generator.valueToCode(block, 'NUM1', Order.NONE);
    const dropdown_name = block.getFieldValue('NAME');
    const value_num2 = generator.valueToCode(block, 'NUM2', Order.NONE);

    const code = `(${value_num1}${dropdown_name}${value_num2})`;
    return [code, Order.NONE];
}