export const field_block = [
    {
        "type": "FIELD_CREATE_PLAYER",
        "tooltip": "创建一个变量 来存储玩家对象",
        "helpUrl": "",
        "message0": "创建玩家变量   变量名: %1 %2 获取方式： %3",
        "args0": [
            {
                "type": "field_input",
                "name": "PLAYER_FIELD_NAME",
                "text": "player"
            },
            {
                "type": "input_dummy",
                "name": "PLAYER_FIELD"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    }
    ,
    {
        "type": "FIELD_GET_PLAYER",
        "tooltip": "从变量中取到玩家对象",
        "helpUrl": "",
        "message0": "获取玩家变量   变量名: %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "PLAYER_FIELD_NAME",
                "text": "player"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "PLAYER",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_STRING",
        "tooltip": "字符串变量",
        "helpUrl": "",
        "message0": "创建字符串变量   变量名: %1 %2 获取方式 %3",
        "args0": [
            {
                "type": "field_input",
                "name": "STRING_NAME",
                "text": "string"
            },
            {
                "type": "input_dummy",
                "name": "STRING_NAME"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_STRING",
        "tooltip": "获取字符串变量",
        "helpUrl": "",
        "message0": "获取字符串变量 %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "string"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_NUMBER",
        "tooltip": "创建数字变量 ",
        "helpUrl": "",
        "message0": "创建数字变量  变量名： %1 %2 获取方式： %3",
        "args0": [
            {
                "type": "field_input",
                "name": "NUMBER",
                "text": "number"
            },
            {
                "type": "input_dummy",
                "name": "NUMBER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    }, {
        "type": "FIELD_GET_NUMBER",
        "tooltip": "获取数字变量",
        "helpUrl": "",
        "message0": "获取数字变量 变量名： %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "number"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "number",
        "colour": 330
    }, {
        "type": "FIELD_CREATE_BOOLEAN",
        "tooltip": "创建布尔变量",
        "helpUrl": "",
        "message0": "创建布尔变量 变量名 %1 %2 获取方式 %3",
        "args0": [
            {
                "type": "field_input",
                "name": "boolean",
                "text": "boolean"
            },
            {
                "type": "input_dummy",
                "name": "boolean"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    }, {
        "type": "FIELD_GET_BOOLEAN",
        "tooltip": "获取布尔变量",
        "helpUrl": "",
        "message0": "获取布尔变量 变量名： %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "boolean"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "boolean_block",
        "colour": 330
    }, {
        "type": "FIELD_CREATE_LOCATION",
        "tooltip": "创建坐标变量",
        "helpUrl": "",
        "message0": "创建坐标变量 变量名： %1 %2 获取方式: %3",
        "args0": [
            {
                "type": "field_input",
                "name": "location",
                "text": "location"
            },
            {
                "type": "input_dummy",
                "name": "location"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    }, {
        "type": "FIELD_GET_LOCATION",
        "tooltip": "获取坐标变量",
        "helpUrl": "",
        "message0": "获取坐标变量 变量名： %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "location",
                "text": "location"
            },
            {
                "type": "input_dummy",
                "name": "location"
            }
        ],
        "output": "location",
        "colour": 330
    }, {
        "type": "FIELD_CREATE_WORLD",
        "tooltip": "创建世界变量",
        "helpUrl": "",
        "message0": "创建世界变量 变量名： %1 %2 获取方式： %3",
        "args0": [
            {
                "type": "field_input",
                "name": "world",
                "text": "world"
            },
            {
                "type": "input_dummy",
                "name": "world"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "world"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    }, {
        "type": "FIELD_GET_WORLD",
        "tooltip": "获取世界变量",
        "helpUrl": "",
        "message0": "获取世界变量 变量名： %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "world"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "world",
        "colour": 330
    }, {
        "type": "FIELD_CREATE_BOSS_BAR",
        "tooltip": "创建bossbar变量",
        "helpUrl": "",
        "message0": "创建bossbar变量 变量名： %1 %2 获取方式： %3",
        "args0": [
            {
                "type": "field_input",
                "name": "bossBar",
                "text": "bossBar"
            },
            {
                "type": "input_dummy",
                "name": "bossBar"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    }, {
        "type": "FIELD_GET_BOSS_BAR",
        "tooltip": "获取bossbar变量",
        "helpUrl": "",
        "message0": "获取bossbar变量 变量名： %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "bossbar"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "bossbar",
        "colour": 330
    }, {
        "type": "FIELD_CREATE_SCOREBOARD",
        "tooltip": "创建计分板变量",
        "helpUrl": "",
        "message0": "创建计分板变量 变量名： %1 %2 获取方式： %3",
        "args0": [
            {
                "type": "field_input",
                "name": "计分板",
                "text": "scoreboard"
            },
            {
                "type": "input_dummy",
                "name": "计分板"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "scoreboard"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    }, {
        "type": "FIELD_GET_SCOREBOARD",
        "tooltip": "获取计分板变量",
        "helpUrl": "",
        "message0": "获取计分板变量 变量名： %1 %2",
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "scoreboard"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "scoreboard",
        "colour": 330
    }









]