import {javascriptGenerator, Order} from "blockly/javascript";

// 创建计分板
javascriptGenerator.forBlock['SCOREBOARD_CREATE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    let value_lines = generator.valueToCode(block, 'lines', Order.NONE);

    const code = `#createScoreboard(${value_name},${value_lines})`;
    return [code, Order.NONE];
}

// 设置计分板
javascriptGenerator.forBlock['SCOREBOARD_SET_SCOREBOARD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_scoreboard = generator.valueToCode(block, 'SCOREBOARD', Order.NONE);

    const code = `#setScoreboard(${value_name},${value_scoreboard});`;
    return code;
}
// 重置计分板
javascriptGenerator.forBlock['SCOREBOARD_RESET_SCOREBOARD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#resetScoreboard(${value_name});`;
    return code;
}