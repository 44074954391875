export const data_source_block = [
    {
        "type": "DATA_SOURCE_PUT_CACHE",
        "tooltip": "可以放任意的value",
        "helpUrl": "",
        "message0": "将某个数据存放在全局变量缓存中 键： %1 值： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_CACHE",
        "tooltip": "返回的是个泛型",
        "helpUrl": "",
        "message0": "从全局变量缓存中拿一个数据 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": null,
        "colour": 30
    }, {
        "type": "DATA_SOURCE_REMOVE_CACHE",
        "tooltip": "",
        "helpUrl": "",
        "message0": "从全局变量缓存中删除一个数据 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_PUT_DATA_BASE",
        "tooltip": "",
        "helpUrl": "",
        "message0": "将某个数据存放在数据库中 键： %1 值： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "number",
                    "string",
                    "boolean_block",
                    "location",
                    "world",
                    "bossbar"
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_DATA_BASE",
        "tooltip": "返回的是个泛型",
        "helpUrl": "",
        "message0": "从数据库中拿一个数据 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": null,
        "colour": 30
    }, {
        "type": "DATA_SOURCE_REMOVE_DATA_BASE",
        "tooltip": "",
        "helpUrl": "",
        "message0": "从数据库中删除一个数据 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_CONTAINS_KEY_CACHE",
        "tooltip": "数据是否存在于全局变量缓存中",
        "helpUrl": "",
        "message0": "数据是否存在于全局变量缓存中 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_CONTAINS_KEY_DATABASE",
        "tooltip": "数据是否存在于数据库中",
        "helpUrl": "",
        "message0": "数据是否存在于数据库中 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_STRING_FROM_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "从配置文件中获取一个字符值 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_LONG_FROM_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "从配置文件中获取一个整数值 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "number",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_DOUBLE_FROM_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "从配置文件中获取一个小数值 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "number",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_BOOLEAN_FROM_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "从配置文件中获取一个布尔值 键： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_PUT_STRING_2_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "往配置文件中存储一个字符值 键： %1 值： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "string",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_PUT_LONG_2_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "往配置文件中存储一个整数值 键： %1 值： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "number",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_PUT_DOUBLE_2_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "往配置文件中存储一个小数值 键： %1 值： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "number",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_PUT_BOOLEAN_2_CONFIG",
        "tooltip": "键值为配置文件的路径，例如 A.B.C.D",
        "helpUrl": "",
        "message0": "往配置文件中存储一个布尔值 键： %1 值： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "boolean_block",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    }



]