export const boss_bar_block = [
    {
        "type": "BOSS_BAR_CREATE_BOSS_BAR",
        "tooltip": "创建一条bossbar",
        "helpUrl": "",
        "message0": "创建一条bossbar 标题： %1 颜色： %2 类型： %3 进度： %4",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "COLOR",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "TAG",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "number",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "bossbar",
        "colour": 345
    }, {
        "type": "BOSS_BAR_ADD_BOSS_BAR",
        "tooltip": "将某个bossbar展示给某玩家",
        "helpUrl": "",
        "message0": "将某个bossbar展示给某玩家 玩家： %1 bossbar： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "bossbar",
                "align": "RIGHT",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }, {
        "type": "BOSS_BAR_REMOVE_BOSS_BAR",
        "tooltip": "将玩家的某个bossbar移除",
        "helpUrl": "",
        "message0": "将玩家的某个bossbar移除 玩家： %1 bossbar： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "bossbar",
                "align": "RIGHT",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }, {
        "type": "BOSS_BAR_SET_PROGRESS",
        "tooltip": "",
        "helpUrl": "",
        "message0": "设置bossbar的进度条 bossbar： %1 范围 0 ~ 1.0  进度条： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345,
        "inputsInline": false
    }, {
        "type": "BOSS_BAR_SET_VISIBLE",
        "tooltip": "设置bossbar的可见性",
        "helpUrl": "",
        "message0": "设置bossbar的可见性 bossbar： %1 布尔： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }, {
        "type": "BOSS_BAR_SET_COLOR",
        "tooltip": "设置bossbar颜色",
        "helpUrl": "",
        "message0": "设置bossbar颜色 bossbar： %1 颜色： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }, {
        "type": "BOSS_BAR_SET_STYLE",
        "tooltip": "设置bossbar类型",
        "helpUrl": "",
        "message0": "设置bossbar类型 bossbar： %1 类型： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }, {
        "type": "BOSS_BAR_SET_TITLE",
        "tooltip": "设置bossbar标题",
        "helpUrl": "",
        "message0": "设置bossbar标题 bossbar： %1 标题： %2",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }, {
        "type": "BOSS_BAR_SET_REMOVE_ALL",
        "tooltip": "移除bossbar所有玩家",
        "helpUrl": "",
        "message0": "移除bossbar所有玩家 bossbar： %1",
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }


]