import {javascriptGenerator, Order} from "blockly/javascript";

javascriptGenerator.forBlock['SERVER_SET_MOTD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_line1 = generator.valueToCode(block, 'line1', Order.NONE);
    const value_line2 = generator.valueToCode(block, 'line2', Order.NONE);

    const code = `#setMotd(${value_name},${value_line1},${value_line2});`;
    return code;
}

javascriptGenerator.forBlock['SERVER_DISPATCH_COMMAND'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#dispatchCommand(${value_name});`;
    return code;
}